/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Syne_400Regular = require('./Syne_400Regular.ttf');
export const Syne_500Medium = require('./Syne_500Medium.ttf');
export const Syne_600SemiBold = require('./Syne_600SemiBold.ttf');
export const Syne_700Bold = require('./Syne_700Bold.ttf');
export const Syne_800ExtraBold = require('./Syne_800ExtraBold.ttf');
